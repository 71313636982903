import React, { useState } from 'react'
import Link from 'next/link'
import cn from 'classnames'
import styles from './Footer.module.sass'
import Subscription from './Subscription'
import Icon from '../../Icon'
import Image from '../../Image'

const menu = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About',
    url: '/about',
  },
  {
    title: 'Python Essentials',
    url: '/python-essentials-data-science',
  },
  {
    title: 'Practical Data  Science',
    url: '/data-science-live-course',
  },
  {
    title: 'Modern Deep Learning',
    url: '/deep-learning-live-course',
  },
  {
    title: 'Impressum & Privacy Policy',
    url: '/impressum',
  },
]

const socials = [
  {
    title: 'twitter',
    size: '18',
    url: 'https://twitter.com/jungleprogram',
  },
  {
    title: 'linkedin',
    size: '16',
    url: 'https://www.linkedin.com/school/jungleprogram',
  },
]

export const Footer = () => {
  const [visible, setVisible] = useState(false)

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn('container', styles.container)}>
          <div className={styles.col}>
            <div className={styles.box}>
              <Link href="/">
                <a>
                  <Image
                    className={styles.logo}
                    src="../../img/logo_icon_black.png"
                    alt="Jungle Program"
                    width="150"
                    height="75"
                  />
                </a>
              </Link>
            </div>
            <div className={cn(styles.item, { [styles.active]: visible })}>
              <div className={styles.category} onClick={() => setVisible(!visible)}>
                Navigation
                <Icon name="arrow-bottom" size="9" />
              </div>
              <div className={styles.menu}>
                {menu.map((x, index) => (
                  <Link activeClassName={styles.active} href={x.url} key={index}>
                    <a className={styles.link}>{x.title}</a>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>Contact</div>
            <div className={styles.info}>
              <p>Jungle Program GmbH</p>
              <p>Zehdenicker Str. 14</p>
              <p>10119 Berlin, Germany</p>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.category}>newsletter</div>
            <div className={styles.info}>
              Subscribe our newsletter to get more fresh news and resources from the Guardians.
            </div>
            <Subscription className={styles.subscription} placeholder="Enter your email" />
          </div>
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn('container', styles.container)}>
          <div className={styles.copyright}>Where developers grow by sharing 🤜🤛</div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import React from 'react'
import Head from 'next/head'

const Layout = ({ children, ...props }) => (
  <>
    <Head prefix="og: http://ogp.me/ns#">
      <title>{props.title}</title>
      <link rel="canonical" href={props.canonical} />

      <meta name="description" content={props.description} />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://www.jungleprogram.com/img/og-image-v3.0.jpg?v=3.0"
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1143" />
      <meta property="og:image:height" content="600" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:url" content="https://www.jungleprogram.com" />

      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="/img/ico/mstile-144x144.png" />
      <meta name="msapplication-square70x70logo" content="/img/ico/mstile-70x70.png" />
      <meta name="msapplication-square150x150logo" content="/img/ico/mstile-150x150.png" />
      <meta name="msapplication-wide310x150logo" content="/img/ico/mstile-310x150.png" />
      <meta name="msapplication-square310x310logo" content="/img/ico/mstile-310x310.png" />
      <link rel="icon" href="/img/ico/favicon.ico" />
      <link rel="icon" type="image/png" sizes="196x196" href="/img/ico/favicon-196x196.png" />
      <link rel="icon" type="image/png" sizes="128x128" href="/img/ico/favicon-128.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/img/ico/favicon-16x16.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/img/ico/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="/img/ico/favicon-96x96.png" />
      <link rel="shortcut icon" type="image/png" sizes="" href="/img/ico/favicon-96x96.png" />
      <link
        rel="apple-touch-icon-precomposed"
        type="image/png"
        sizes="57x57"
        href="/img/ico/apple-touch-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        type="image/png"
        sizes="114x114"
        href="/img/ico/apple-touch-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        type="image/png"
        sizes="72x72"
        href="/img/ico/apple-touch-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        type="image/png"
        sizes="144x144"
        href="/img/ico/apple-touch-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        type="image/png"
        sizes="60x60"
        href="/img/ico/apple-touch-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        type="image/png"
        sizes="120x120"
        href="/img/ico/apple-touch-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        type="image/png"
        sizes="76x76"
        href="/img/ico/apple-touch-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        type="image/png"
        sizes="152x152"
        href="/img/ico/apple-touch-icon-152x152.png"
      />

      <link rel="preconnect" href="https://use.typekit.net" />
      <link rel="preconnect" href="https://p.typekit.net" />
      <link async defer rel="stylesheet" href="https://use.typekit.net/ete6mbp.css" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap"
        rel="stylesheet"
      />
    </Head>
    {children}
  </>
)

export default Layout

import React from 'react'
import cn from 'classnames'
import styles from './Subscription.module.sass'
import Icon from '../../../Icon'

import { Stack, Text, FormControl, Input, useToast } from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import axios from 'axios'

const Subscription = ({ className, placeholder }) => {
  const toast = useToast()
  
  return (
    <>
      <Formik
        initialValues={{ email: '', theme: '' }}
        validate={ values => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Email address required.'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address.'
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm, setFieldError }) => {
          const headers = {}
          axios.post('/api/subscribe', {
            email: values.email,
            headers,
          })
          .then(function (response) {
            resetForm();
            toast({
              title: "Thank you!",
              description: "You're now subscribed to the newsletter.",
              status: "success",
              duration: 10000,
              isClosable: true,
            })
          })
          .catch(function (error) {
            setFieldError('email', error.response.data.message);
          })
          .then(function () {
            // always executed
            setSubmitting(false)
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className={cn(styles.form, className)}>
            <Stack
              spacing="2"
              direction={{ base: 'column' }}
              align={{ md: 'left' }}
              justify="space-between"
              mx="auto"
              px={{ base: '3', md: '0', sm: '0', xs: '0' }}
            >
              <Field name="email">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.email && form.touched.email}>
                    <Input
                      {...field}
                      type="email"
                      id="email"
                      placeholder="Enter your email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email || ''}
                      width="100%"
                      height="48px"
                      padding="0 48px 0 14px"
                      borderRadius="24px"
                      borderWidth="2px"
                      borderStyle="solid"
                      borderColor="#E6E8EC"
                      fontFamily="Poppins, sans-serif"
                      fontSize="14px"
                      color="#23262F"
                      textColor="#23262F"
                      className={styles.input}
                    />
                    <button
                      className={styles.btn}
                      disabled={isSubmitting}
                      type="submit"
                    >
                      <Icon name="arrow-right" size="14" />
                    </button>
                  </FormControl>
                )}
              </Field>
            </Stack>
            <Text
              color="red.600"
              mt="2"
              fontSize="sm"
            >
              {errors.email && touched.email && errors.email}&nbsp;
              {errors.theme && touched.theme && errors.theme}
            </Text>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Subscription
import React, { useState } from 'react'
import Link from 'next/link'
import cn from 'classnames'
import styles from './Header.module.sass'
import Image from '../../Image'

import { useSession } from 'next-auth/react'

const navLinks = [
  {
    title: 'About',
    url: '/about',
  },
  {
    title: 'Guardians',
    url: '/become-a-guardian',
  },
]

const contact = [
  {
    title: '',
    content: '',
  },
]

export const Headers = () => {
  const { data: status, session } = useSession()

  const [visibleNav, setVisibleNav] = useState(false)

  return (
    <header className={styles.header}>
      <div className={cn('container', styles.container)}>
        <Link href="/" onClick={() => setVisibleNav(false)}>
          <a className={styles.logo}>
            <Image src="../../img/logo_black.svg" alt="Jungle Program" width="150" height="75" />
          </a>
        </Link>
        <div className={cn(styles.wrap, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            {navLinks.map((x, index) => (
              <Link
                activeClassName={styles.active}
                href={x.url}
                key={index}
                onClick={() => setVisibleNav(false)}
              >
                <a className={styles.link}>{x.title}</a>
              </Link>
            ))}
          </nav>
          <div className={styles.details}>
            <div className={styles.contact}>
              {contact.map((x, index) => (
                <div className={styles.element} key={index}>
                  <div className={styles.category}>{x.title}</div>
                  <div className={styles.text}>{x.content}</div>
                </div>
              ))}
            </div>
          </div>
          {status ? (
            <Link href="/app/onboarding">
              <a className={cn('button-stroke button-small', styles.button)}>Edit my profile</a>
            </Link>
          ) : (
            <></>
          )}
        </div>

        {status ? (
          <Link href="/app/onboarding">
            <a className={cn('button-stroke button-small', styles.button)}>Edit my profile</a>
          </Link>
        ) : (
          <></>
        )}

        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  )
}

export default Headers

import React from 'react'
// import useDarkMode from 'use-dark-mode'

const Image = ({ className, src, srcDark, srcSet, srcSetDark, alt, width, height }) => {
  // const darkMode = useDarkMode(false);

  return (
    <img
      className={className}
      // srcSet={darkMode.value ? srcSetDark : srcSet}
      // src={darkMode.value ? srcDark : src}
      src={src}
      srcSet={srcSet}
      alt={alt}
      width={width}
      height={height}
    />
  );
};

export default Image;
